import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Mailchimp from "../components/mailchimp";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

const Donate = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  p {
    margin: 0.5em 0;
  }
`;

const DonateButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #000;
  padding: 0.5em 1em;
  border-radius: 5px;
  margin: 1.5em 0;
  cursor: pointer;
  width: 250px;
  transition: all 0.3s ease;
  &:hover {
    background-color: #CCC;
    color: #fff;
    a {
      font-family: ABC Favorite Lining, serif; 
            transition: 0.3s;
    }
  } 
    a { 
      color: #000;
      text-decoration: none;
    }
`;

const Impressum = styled.div`
 img {
  width: 120px;
  height: auto;
 }
`;

const About = ({ data }) => {
  const posts = data.allWpPage.edges;
  return (
    <div className="container">
      <Layout>
        <div>
          {posts.map((post) => {
            return (
              <div key={post.node.id}>
                <div className="text">
                  <h1>{post.node.title}</h1>
                  {post.node.about.content ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: post.node.about.content,
                      }}
                    />
                  ) : null}
                </div>
                <Mailchimp />
                <div className="donate">
                  <Donate>
                    <p>
                      {post.node.about.donateTitle
                        ? post.node.about.donateTitle
                        : null}
                    </p>
                  </Donate>
                  {post.node.about.qrCode ? (
                    <GatsbyImage
                      image={
                        post.node.about.qrCode.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      width="100"
                      height="auto"
                      layout="constrained"
                      objectFit="contain"
                      objectPosition="center"
                      alt="qr code"
                    />
                  ) : null}
                  {post.node.about.donateText ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: post.node.about.donateText,
                      }}
                    />
                  ) : null}
                  {post.node.about.impressum ? (
                    <Impressum
                      dangerouslySetInnerHTML={{
                        __html: post.node.about.impressum,
                      }}
                    />
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </Layout>
    </div>
  );
};

export const Head = () => (
  <>
    <title>For – About</title>
    <meta name="description" content="For ist ein nicht gewinnorientierter Ausstellungsraum in Basel (CH), der zeitgenössische Kunst zeigt. Der Fokus liegt auf Arbeiten, die sich gemeinsam mit dem Publikum kritisch-imaginativ mit populären Phänomenen unserer Gegenwart auseinandersetzen. Ergänzend zu den Ausstellungen erscheint ein kulturwissenschaftliches Magazin." />
  </>
);
export default About;
export const pageQuery = graphql`
  query {
    allWpPage(filter: { title: { eq: "About" } }) {
      edges {
        node {
          id
          title
          slug
          about {
            content
            donateText
            donateTitle
            impressum
            qrCode {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;
