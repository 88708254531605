import addToMailchimp from "gatsby-plugin-mailchimp";
import React from "react"
import styled from "styled-components";



const Input = styled.input`
    background: transparent;
    outline: 0;
    border: none;
    border-bottom: 2px solid white;
    font-family: ABC Favorite, serif;
    font-dsiplay: fallback;
    font-size: 0.9em;
    color: white;
`;

const Submit = styled.input.attrs({ type: 'submit' })`
    background: transparent;
    outline: 0;
    font-size: 0.9em;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-bottom: 0;
    text-transform: uppercase;
    box-shadow: 0px;
    outline: none;
    transition: 0.15s;
    text-align: center;
    margin-bottom: 3em;
    &:active {
        background-color: #f1ac15;
    }
`

export class Mailchimp extends React.Component {
  constructor() {
    super()
    this.state = { email: "", result: null }
  }

  _handleSubmit = async e => {
    e.preventDefault()
    this.setState({email: ''})
    const result = await addToMailchimp(this.state.email)
    this.setState({result: result})
  }
handleChange = event => {
    this.setState({ email: event.target.value })
  }

  render() {
console.log(this.state.result)
    return (    
        <>
        <form onSubmit={this._handleSubmit}>
        <label htmlFor="outlined-email-input">Newsletter: </label>
        <Input
          id="outlined-email-input"
          label="Email"
          type="email"
          value={this.state.email}
          name="email"
          autoComplete="email"
          onChange={this.handleChange}
        />
        <Submit
          label="ok"
          type="submit"
          value="Ok"
       />
      </form>
        {this.state.result && this.state.result.msg && ( <p>{this.state.result.msg}</p> )}
        </>

    )
  }
}

export default Mailchimp